
.maps-page {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.frame {
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none; /* Prevent mouse events on the frame */
}

.frame img {
    width: 40%;
    height: auto;
    z-index: 1;
}

.frame-tl {
    position: absolute;
    top: 0;
    left: 0;
}

.frame-tr {
    position: absolute;
    top: 0;
    right: 0;
}

.frame-bl {
    position: absolute;
    bottom: 0;
    left: 0;
}

.frame-br {
    position: absolute;
    bottom: 0;
    right: 0;
}

.maps-container, .maps-container-1 {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 20px 0; /* Atur jarak vertikal antar elemen */
    max-width: 600px;
    width: 80%; /* Atur lebar sesuai kebutuhan */
    text-align: center;
}

.maps-container {
    font-size: 12pt;
    text-align: center;
    color: #333;
    margin: 0;
}

.maps-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.maps-text {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: bold;
}

.maps-button {
    background-color: #f28b82;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}

.maps-button:hover {
    background-color: #357ae8;
}