/* src/css/QuotesPage.css */
.quotes-page {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.frame {
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none; /* Prevent mouse events on the frame */
}

.frame img {
    width: 40%;
    height: auto;
    z-index: 1;
}

.frame-tl {
    position: absolute;
    top: 0;
    left: 0;
}

.frame-tr {
    position: absolute;
    top: 0;
    right: 0;
}

.frame-bl {
    position: absolute;
    bottom: 0;
    left: 0;
}

.frame-br {
    position: absolute;
    bottom: 0;
    right: 0;
}

.mempelai-container {
    background-color: white;
    border-radius: 10px;
    /*box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);*/
    height: 500px;
    width: 260px;
    padding: 30px;
    /*max-width: 70%;*/
    /*max-height: 70%;*/
    text-align: center;
}

.mempelai-container .quote-text {
    font-size: 12pt;
    text-align: left;
    color: #333;
    margin: 0;
}

.greeting-text {
    font-size: 12pt;
    font-weight: bold;
}
.invite-text {
    font-family: 'Arial, sans-serif';
    margin: 10px 0;
}

.mempelai {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;
}

.mempelai-item {
    text-align: center;
}

.mempelai-photo {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 10px;
}

.mempelai-name {
    font-weight: bold;
    font-size: 15pt;
}

.mempelai-info, .mempelai-ig {
    font-size: 10pt;
    margin: 5px 0;
}