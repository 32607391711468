.first-page {
    position: relative;
    height: 106%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.frame {
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none; /* Prevent mouse events on the frame */
}

.frame img {
    width: 40%;
    height: auto;
}

.frame-tl {
    position: absolute;
    top: 0;
    left: 0;
}

.frame-tr {
    position: absolute;
    top: 0;
    right: 0;
}

.frame-bl {
    position: absolute;
    bottom: 0;
    left: 0;
}

.frame-br {
    position: absolute;
    bottom: 0;
    right: 0;
}
.first-page-content {
    position: relative;
    align-items: center;
    z-index: 1;
    /*background-color: white;*/
    padding: 20px;
    border-radius: 10px;
    /*box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);*/
}

.invitation-title {
    font-size: 1.2em;
    color: #555;
    margin-bottom: 10px;
    text-align: center;
}

.photo-container {
    display: flex;
    justify-content: center; /* Horizontally center the image */
    align-items: center; /* Vertically center the image, if needed */
    margin: 20px 0;
}

.couple-photo {
    width: 50%;
    border-top-left-radius: 90px;
    border-top-right-radius: 90px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.couple-names {
    text-align: center;
    font-size: 2em;
    color: #333;
    margin: 10px 0;
}

.invitation-to {
    text-align: center;
    font-size: 1em;
    color: #777;
}

.guest-tittle {
    text-align: center;
    font-size: 1.2em;
    color: #333;
    margin: 10px 0;
}

.guest-name {
    text-align: center;
    font-size: 1.2em;
    font-weight: bold;
    color: #333;
    margin: 10px 0;
}

.button-container {
    display: flex;
    justify-content: center; /* Horizontally center the button */
    margin: 20px 0;
}

.open-invitation-btn {
    justify-content: center;
    background-color: #f28b82;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.open-invitation-btn:hover {
    background-color: #e57373;
}

.quote-text {
    font-size: 1.5em;
    margin-top: 20px;
    color: #555;
}