/* src/css/QuotesPage.css */
.quotes-page {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.frame {
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none; /* Prevent mouse events on the frame */
}

.frame img {
    width: 40%;
    height: auto;
}

.frame-tl {
    position: absolute;
    top: 0;
    left: 0;
}

.frame-tr {
    position: absolute;
    top: 0;
    right: 0;
}

.frame-bl {
    position: absolute;
    bottom: 0;
    left: 0;
}

.frame-br {
    position: absolute;
    bottom: 0;
    right: 0;
}

/*.quote-text {*/
/*    margin-top: 20px;*/
/*    font-size: 1.5em;*/
/*    text-align: center;*/
/*}*/

.quote-container {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 30px;
    max-width: 70%;
    max-height: 70%;
    text-align: center;
}

.quote-container .quote-text {
    font-size: 12pt;
    text-align: left;
    color: #333;
    margin: 0;
}