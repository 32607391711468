
.akad-page {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.frame {
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none; /* Prevent mouse events on the frame */
}

.frame img {
    width: 40%;
    height: auto;
    z-index: 1;
}

.frame-tl {
    position: absolute;
    top: 0;
    left: 0;
}

.frame-tr {
    position: absolute;
    top: 0;
    right: 0;
}

.frame-bl {
    position: absolute;
    bottom: 0;
    left: 0;
}

.frame-br {
    position: absolute;
    bottom: 0;
    right: 0;
}

.akad-container {
    background-color: white;
    border-top-right-radius: 160px;
    border-top-left-radius: 160px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    height: 400px;
    width: 260px;
    padding: 30px;

    text-align: center;
}

.akad-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
}

.akad-date {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.akad-day {
    font-size: 18px;
    margin-bottom: 5px;
}

.akad-day-number-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.line-above, .line-below {
    width: 100%;
    border: none;
    border-top: 2px solid #f06292; /* Adjust color to match the design */
    margin: 0;
}

.line-above {
    margin-bottom: 5px;
}

.line-below {
    margin-top: 5px;
}

.akad-day-number {
    font-size: 48px;
    font-weight: bold;
}

.akad-month-year {
    font-size: 18px;
    margin-top: 10px;
}

.akad-time {
    font-size: 16px;
    margin-bottom: 10px;
}

.akad-location-name {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
}

.akad-location-address {
    font-size: 14px;
    margin-bottom: 20px;
}

.akad-link-btn {
    background-color: #f06292;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    text-decoration: none;
}

.akad-link-btn:hover {
    background-color: #ec407a;
}