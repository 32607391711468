
.gift-page {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.frame {
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none; /* Prevent mouse events on the frame */
}

.frame img {
    width: 40%;
    height: auto;
}

.frame-tl {
    position: absolute;
    top: 0;
    left: 0;
}

.frame-tr {
    position: absolute;
    top: 0;
    right: 0;
}

.frame-bl {
    position: absolute;
    bottom: 0;
    left: 0;
}

.frame-br {
    position: absolute;
    bottom: 0;
    right: 0;
}

.gift-header {
    font-size: 24px;
    color: #f28b82;
    margin-bottom: 10px;
}

.gift-description {
    font-size: 16px;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
}

.button-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
}

.gift-button {
    background-color: #f28b82;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 16px;
}

.gift-button:hover {
    background-color: #f28b82;
}

.account-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.account-box {
    display: flex;
    align-items: center;
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.bank-icon {
    width: 50px;
    height: 50px;
    margin-right: 20px;
}

.account-details {
    text-align: left;
}

.account-number {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
}

.account-bank {
    font-size: 14px;
    color: #666;
}

.copy-button {
    background-color: #f28b82;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    margin-left: 10px;
}

.copy-button:hover {
    background-color: #f28b82;
}