
.resepsi-page {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.frame {
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none; /* Prevent mouse events on the frame */
}

.frame img {
    width: 40%;
    height: auto;
    z-index: 1;
}

.frame-tl {
    position: absolute;
    top: 0;
    left: 0;
}

.frame-tr {
    position: absolute;
    top: 0;
    right: 0;
}

.frame-bl {
    position: absolute;
    bottom: 0;
    left: 0;
}

.frame-br {
    position: absolute;
    bottom: 0;
    right: 0;
}

.gallery-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 10px;
    width: 90%;
    max-width: 800px;
    margin-top: 20px;
}

.gallery-text {
    font-size: 16pt;
    font-weight: bold;
    color: #333;
    margin: 0;
}

.gallery-item {
    overflow: hidden;
    position: relative;
}

.gallery-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.item1 {
    grid-column: span 2;
    grid-row: span 2;
}

.item2 {
    grid-column: span 1;
    grid-row: span 1;
}

.item3 {
    grid-column: span 1;
    grid-row: span 2;
}

.item4 {
    grid-column: span 1;
    grid-row: span 1;
}

.item5 {
    grid-column: span 1;
    grid-row: span 1;
}

.item6 {
    grid-column: span 2;
    grid-row: span 1;
}