/* src/App.css */
.app-background {
    background-color: #f8f9fa;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.app-container {
    background-image: url("/public/bg.webp");
    background-size: cover;
    /*background-color: #ffffff;*/
    width: 100%;
    max-width: 400px;
    height: calc(100% - 8%); /* Adjusted for navbar height */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow: hidden;
    padding-bottom: 50px; /* Ensure content is above the navbar */
}

/*.custom-navbar {*/
/*  display: flex;*/
/*  justify-content: space-around;*/
/*  position: fixed;*/
/*  bottom: 0;*/
/*  height: 8%;*/
/*  width: 100%;*/
/*  max-width: 400px;*/
/*  background-color: #ffffff;*/
/*  !*box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);*!*/
/*  padding: 10px 0;*/
/*  margin: 0;*/
/*  list-style: none;*/
/*  !*border-top: 1px solid #e7e7e7;*!*/
/*  z-index: 2;*/
/*}*/

.button-music-container {
    display: flex;
    opacity: 1;
    z-index: 1000; /* Ensure the button is on top of other elements */
}

.music-toggle {
    bottom: 16%;
    left: 85%;
    position: fixed;
    height: 50px;
    width: 50px;
    opacity: 0.5;
    /*filter: invert(41%) sepia(100%) saturate(296%) hue-rotate(159deg) brightness(86%) contrast(89%);*/
    /*background-color: #007bff;*/
    border: none;
    border-radius: 50%;
    cursor: pointer;
}

.button-music-container .music-toggle .img-toggle {
    filter: invert(41%) sepia(100%) saturate(296%) hue-rotate(159deg) brightness(86%) contrast(89%);
    opacity: 1;
}

/*.nav-item {*/
/*  text-align: center;*/
/*  align-content: center;*/

/*}*/

/*
.nav-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #333333;
  font-size: 14px;
}

.nav-link .nav-title {
  font-size: 12px;
  font-weight: bold;
  margin-top: 4px;
}
*/


/*.nav-item.active .nav-link {*/
/*  filter: invert(41%) sepia(100%) saturate(296%) hue-rotate(159deg) brightness(86%) contrast(89%);*/
/*}*/

/*.nav-item .nav-link:hover {*/
/*  color: #0056b3;*/
/*}*/

/*.nav-item .nav-link .img-icon {*/
/*  width: 28px;*/
/*  height: auto;*/
/*}*/
/*.nav-item.active .nav-link .img-icon{*/
/*  filter: invert(41%) sepia(100%) saturate(296%) hue-rotate(159deg) brightness(86%) contrast(89%);*/
/*}*/

.custom-navbar {
    display: flex;
    justify-content: space-between; /* Ganti space-around dengan space-between */
    position: fixed;
    bottom: 0;
    height: 8%;
    width: 100%;
    max-width: 400px;
    background-color: #ffffff;
    overflow-x: auto; /* Tambahkan ini untuk mengaktifkan penggeseran horizontal */
    padding: 10px 0;
    margin: 0;
    list-style: none;
    z-index: 2;
}

.custom-navbar::-webkit-scrollbar {
    display: none; /* Sembunyikan scrollbar */
}

.nav-item {
    /*flex: 0 0 auto; !* Pastikan item tidak menyusut *!*/
    flex: 0 0 20%;
    text-align: center;
    align-content: center;
    padding: 0 0px; /* Berikan padding horizontal agar lebih rapi */
}

.nav-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: #333333;
    font-size: 14px;
    padding: 5px 0; /* Berikan padding vertikal */
}

.nav-link .nav-title {
    font-size: 12px;
    font-weight: bold;
    margin-top: 4px;
    line-height: 1.2; /* Tambahkan line-height untuk jarak yang lebih baik */
    text-align: center;
}

.nav-item.active .nav-link {
    filter: invert(41%) sepia(100%) saturate(296%) hue-rotate(159deg) brightness(86%) contrast(89%);
}

.nav-item .nav-link:hover {
    filter: invert(41%) sepia(100%) saturate(296%) hue-rotate(159deg) brightness(86%) contrast(89%);
}

.nav-item .nav-link .img-icon {
    width: 28px;
    height: auto;
}

.nav-item.active .nav-link .img-icon:hover{
    filter: invert(41%) sepia(100%) saturate(296%) hue-rotate(159deg) brightness(86%) contrast(89%);
}

.nav-item.active .nav-link .img-icon {
    filter: invert(41%) sepia(100%) saturate(296%) hue-rotate(159deg) brightness(86%) contrast(89%);
}